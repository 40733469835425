import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack, Text,
  useColorMode, VStack
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import QuadKachelnInvRelations from "../components/QuadKachelnInvRelations";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import ImgMaskReveal from "../DefinitionComponents/Animation-Components/ImgMaskReveal";


const data = [
  { id:1,
    text: "Directors' Dealings – Zeichnung neuer Aktien im Rahmen der Kapitalerhöhung Limit45 GmbH",
  
    url: "https://www.dgap.de/dgap/News/directors_dealings/zeichnung-neuer-aktien-rahmen-der-kapitalerhoehung-limit-gmbh/?newsID=1486483",
  },
  { id:2,
    text: "Directors' Dealings – Zeichnung neuer Aktien im Rahmen der Kapitalerhöhung Regina Michel",
    url: "https://www.dgap.de/dgap/News/directors_dealings/zeichnung-neuer-aktien-rahmen-der-kapitalerhoehung-michel-regina/?newsID=1486485",
  },
  { id:3,
    text: "Directors' Dealings – Zeichnung neuer Aktien im Rahmen der Kapitalerhöhung Project Mill Gesellschaft für interdisziplinären Know-how-Transfer GmbH",
    url: "https://www.dgap.de/dgap/News/directors_dealings/zeichnung-neuer-aktien-rahmen-der-kapitalerhoehung-project-mill-gesellschaft-fuer-interdisziplinaeren-knowhowtransfer-gmbh/?newsID=1486511",
  },

];

const News = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });

  return (
    <>
      <Helmet>
        <title>tokentus | Directors' Dealings</title>
        <meta name="description" content="Directors’ Dealings provided by tokentus investment AG." />
        <link rel="canonical" href="https:/tokentus.com/directorsdealings" />
      </Helmet>

      <ImgTextModule
        src={"directorsdealings.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="55vh"
        mediaOpacity={0.8}
        bgColor="brand.black"
      >
        <Container variant="layoutContainer" mt={8}>
          <Box p={8}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="brand.medgray" fontWeight="normal" fontSize="6xl">
                Directors' Dealings.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>
      {/* NEWS */}

     
      <Container
        variant="layoutContainer"
        px={{ base: 0, lg: 10 }}
        py={{ base: 0, lg: 10 }}
      >
        <Flex
          flexWrap="wrap"
          flexDirection="column"
          justifyContent={"space-between"}
        >
    {data.map((data) => (
          
          <Box w={{ base: "100%", lg: "100%" }}>
            <Flex wrap="wrap" flexDirection={"row"}>
              <HStack
                visibility={{ base: "hidden", lg: "visible" }}
                w="20%"
                p={0}
                spacing={0}
                bg={" brand.darkgray"}
              >
                <ImgMaskReveal
                  imgsrc={"newsthumb2.png"}
                  initScale={1.5}
                  delay={0}
                  duration={2}
                  threshold={0.4}
                  triggerOnce={true}
                />
              </HStack>
              <VStack
                w={{ base: "100%", lg: "80%" }}
                px={10}
                py={10}
                alignItems={"flex-start"}
                bg={"brand.lightgray"}
              >
                <Heading
                  fontSize="md"
                  fontWeight="normal"
                  lineHeight={"none"}
                  color="brand.blue"
                >
                  {data.date}
                </Heading>
                <Heading
                  w={{ base: "100%", lg: "70%" }}
                  fontSize="xl"
                  fontWeight="normal"
                  lineHeight={"shorter"}
                  color="brand.meddarkgray"
                >
                  {data.text}
                </Heading>
                <Link href={data.url} target="_blank" >
                  <Button
                    color="white"
                    size={"lg"}
                    fontWeight={"normal"}
                    px={6}
                    backgroundColor="brand.meddarkgray"
                
                  >
                    ext. Link
                  </Button>
                </Link>
              </VStack>
            </Flex>
          </Box>

))}

        </Flex>

      </Container>
      <QuadKachelnInvRelations/>
    </>
  );
};

export default News;
