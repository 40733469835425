import { Box, Button, Container, GridItem, Text } from "@chakra-ui/react";
import React from "react";
import ImgTextModule from "../components/ImgTextModule";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";

export default function QuadKachelnInvRelations() {
	return (
		<Container
			variant="layoutContainer"
			mt={0.5}
			mb={0.5}
			display="grid"
			gridGap={0.5}
			gridTemplateColumns={{
				lg: "repeat(4, 2fr);",
				md: "repeat(2, 1fr);",
				base: "repeat(1, 1fr);",
			}}>
			<GridItem colSpan={{ base: "1", md: "2" }}>
				<ImgTextModule
					w="100%"
					src={
						"646859_Laptop_Hands_Keyboard_Typing_By_Adrian_Vidak_Artlist_HD-.mp4"
					}
					delay={0.9}
					duration={1}
					threshold={0.4}
					initialY={0}
					initialX={0}
					video={true}
					colStart={1}
					colEnd={3}
					mediaOpacity={0.8}
					bgColor="black">
					<FadeInAnimation threshold={0.4} duration={1}>
						<Box p={8} minH="450px">
							<FadeInAnimation
								threshold={0.4}
								delay={0.4}
								duration={0.7}
								initialX={0}
								initialY={-10}>
								<Text color="white" fontSize="4xl">
									Investor Relations Mailing.
								</Text>
							</FadeInAnimation>

							<FadeInAnimation
								threshold={0.4}
								delay={1}
								duration={0.7}
								initialX={0}
								initialY={10}>
								<Text color="brand.green" fontSize="md">
									Sign up here and be informed.
								</Text>
							</FadeInAnimation>
							<RouterLink
								isExternal={false}
								link={"/investormailing"}>
								<Button
									mt={4}
									variant="solid"
									bg="brand.green"
									color="brand.darkgray">
									Investor Relations Mailing
								</Button>
							</RouterLink>
						</Box>
					</FadeInAnimation>
				</ImgTextModule>
			</GridItem>
			<GridItem colSpan={{ base: "1", md: "2" }}>
				<ImgTextModule
					w="100%"
					src={"news.mp4"}
					delay={0.9}
					duration={1}
					threshold={0.4}
					colStart={3}
					colEnd={4}
					initialY={0}
					initialX={0}
					video={true}
					mediaOpacity={0.8}
					bgColor="black">
					<FadeInAnimation threshold={0.4} duration={1}>
						<Box p={8} minH="450px">
							<FadeInAnimation
								threshold={0.4}
								delay={0.4}
								duration={0.7}
								initialX={0}
								initialY={-10}>
								<Text color="white" fontSize="4xl">
									Newsroom.
								</Text>
							</FadeInAnimation>

							<FadeInAnimation
								threshold={0.4}
								delay={1}
								duration={0.7}
								initialX={0}
								initialY={10}>
								<Text color="brand.green" fontSize="md">
									News from tokentus.
								</Text>
							</FadeInAnimation>
							<RouterLink isExternal={false} link={"/news"}>
								<Button
									mt={4}
									variant="solid"
									bg="brand.green"
									color="brand.darkgray">
									News
								</Button>
							</RouterLink>
						</Box>
					</FadeInAnimation>
				</ImgTextModule>
			</GridItem>
			<GridItem colSpan={{ base: "1", md: "2" }}>
				<ImgTextModule
					w="100%"
					src={"AdHoc.mp4"}
					delay={0.9}
					duration={1}
					threshold={0.4}
					initialY={0}
					initialX={0}
					video={true}
					colStart={1}
					colEnd={3}
					mediaOpacity={0.8}
					bgColor="black">
					<FadeInAnimation threshold={0.4} duration={1}>
						<Box p={8} minH="450px">
							<FadeInAnimation
								threshold={0.4}
								delay={0.4}
								duration={0.7}
								initialX={0}
								initialY={-10}>
								<Text color="white" fontSize="4xl">
									Ad-hoc Messages.
								</Text>
							</FadeInAnimation>

							<FadeInAnimation
								threshold={0.4}
								delay={1}
								duration={0.7}
								initialX={0}
								initialY={10}>
								<Text color="brand.green" fontSize="md">
									Relevant Messages. Direct Action.
								</Text>
							</FadeInAnimation>
							<RouterLink isExternal={false} link={"/adhoc"}>
								<Button
									mt={4}
									variant="solid"
									bg="brand.green"
									color="brand.darkgray">
									Downloads
								</Button>
							</RouterLink>
						</Box>
					</FadeInAnimation>
				</ImgTextModule>
			</GridItem>
			<GridItem colSpan={{ base: "1", md: "2" }}>
				<ImgTextModule
					w="100%"
					src={"directorsdealings.mp4"}
					delay={0.9}
					duration={1}
					threshold={0.4}
					initialY={0}
					initialX={0}
					video={true}
					colStart={1}
					colEnd={3}
					mediaOpacity={0.8}
					bgColor="black">
					<FadeInAnimation threshold={0.4} duration={1}>
						<Box p={8} minH="450px">
							<FadeInAnimation
								threshold={0.4}
								delay={0.4}
								duration={0.7}
								initialX={0}
								initialY={-10}>
								<Text color="white" fontSize="4xl">
									Directors' Dealings.
								</Text>
							</FadeInAnimation>

							<RouterLink
								isExternal={false}
								link={"/directorsdealings"}>
								<Button
									mt={4}
									variant="solid"
									bg="brand.green"
									color="brand.darkgray">
									Continue
								</Button>
							</RouterLink>
						</Box>
					</FadeInAnimation>
				</ImgTextModule>
			</GridItem>
			<GridItem colSpan={{ base: "1", md: "4" }}>
				<ImgTextModule
					w="100%"
					src={"Eventcalendar.mp4"}
					delay={0.9}
					duration={1}
					threshold={0.4}
					initialY={0}
					initialX={0}
					video={true}
					colStart={1}
					colEnd={3}
					mediaOpacity={0.8}
					bgColor="black">
					<FadeInAnimation threshold={0.4} duration={1}>
						<Box p={8} minH="450px">
							<FadeInAnimation
								threshold={0.4}
								delay={0.4}
								duration={0.7}
								initialX={0}
								initialY={-10}>
								<Text color="white" fontSize="4xl">
									Event Calendar.
								</Text>
							</FadeInAnimation>

							<RouterLink isExternal={false} link={"/events"}>
								<Button
									mt={4}
									variant="solid"
									bg="brand.green"
									color="brand.darkgray">
									Continue
								</Button>
							</RouterLink>
						</Box>
					</FadeInAnimation>
				</ImgTextModule>
			</GridItem>
		</Container>
	);
}
